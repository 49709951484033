import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { UserContext } from "../../../context/UserContext";
import EditIcon from "@mui/icons-material/Edit";
import { StyledButton } from "../../common/Buttons";
import { Button, Grid, Stack } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PublicIcon from "@mui/icons-material/Public";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DescriptionIcon from "@mui/icons-material/Description";

const cleanUrl = (url: string) => {
  if (url) {
    if (url.includes("http://") || url.includes("https://")) {
      return url;
    } else {
      return "https://" + url;
    }
  } else {
    return "";
  }
};

function Provider(props: any) {
  const provider = props.providerContent;
  const { user } = React.useContext(UserContext);
  const selectedProviderFlag = props.selectedProvider.find((p: any) => {
    return p.orgName === provider.orgName;
  });

  const openNotesEditor = (resource: any) => {
    props.setEditingResource({ ...provider, provider_id: resource.id });
    props.setProviderNotesOpen(true);
  };

  function openEditModal(resource: any) {
    props.setEditingResource({ ...provider, provider_id: resource.id });
    props.setResourceEditorOpen(true);
  }

  return (
    <div
      className={`bg-white rounded-lg shadow-md pb-2 pt-2 ${
        selectedProviderFlag ? "border-2 border-magenta" : ""
      }`}
    >
      <div className="grid grid-cols-3 gap-2">
        {/* <h3 className={styles.title}>{resource.title}</h3> */}
        <div className="col-start-1 col-span-1">
          <div className="flex flex-col">
            <div className="w-60 h-60 bg-white flex items-center justify-center rounded-lg m-1">
              {provider.imagePath ? (
                <img
                  src={provider.imagePath}
                  className="object-contain w-full h-full"
                  alt="Resource"
                />
              ) : (
                <img
                  src={`${process.env.REACT_APP_API_DOMAIN}/images/dummy-image.jpg`}
                  className="object-contain w-full h-full"
                  alt="Resource"
                />
              )}
            </div>

            <div className="flex flex-col items-start ml-2">
              {selectedProviderFlag ? (
                <Stack spacing={1}>
                  <StyledButton
                    className="bg-gray-300 hover:bg-gray-300 font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    sx={{ color: "white" }}
                    onClick={() => {
                      //Unselect
                      let newSelectedProvider = props.selectedProvider.filter(
                        (p: any) => p.orgName !== provider.orgName
                      );
                      props.setSelectedProvider(newSelectedProvider);
                    }}
                  >
                    Unselect
                  </StyledButton>
                  <StyledButton
                    className="bg-gray-300 hover:bg-gray-300 font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    sx={{ color: "white" }}
                    onClick={() => {
                      props.setOpen(true);
                    }}
                  >
                    Connect selected to Case
                  </StyledButton>
                </Stack>
              ) : (
                <>
                  <StyledButton
                    className="bg-gray-300 hover:bg-gray-300 font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    sx={{ color: "white" }}
                    onClick={() => {
                      let clickedProvide = props.selectedProvider.find(
                        (p: any) => p.orgName === provider.orgName
                      );
                      if (!clickedProvide) {
                        props.setSelectedProvider([
                          ...props.selectedProvider,
                          provider,
                        ]);
                      }
                    }}
                  >
                    Select Provider
                  </StyledButton>
                </>
              )}
              {provider.onepagerPath && (
                <>
                  <a
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    href={provider.onepagerPath}
                    target="_blank"
                  >
                    View One Pager
                  </a>
                </>
              )}
              {provider.factsheets && (
                <>
                  <button
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    onClick={() => {
                      props.setProviderFactsheetOpen(true);
                      props.setEditingResource(provider);
                    }}
                  >
                    View Fact Sheet
                  </button>
                </>
              )}
              {provider.notes && (
                <>
                  <button
                    className="bg-yellow hover:bg-magenta text-white font-bold py-2 w-[220px] rounded-xl hover:cursor-pointer mt-2"
                    onClick={() => {
                      openNotesEditor(provider);
                    }}
                  >
                    View Notes
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-start-2 col-span-2">
          <div className="flex flex-col items-start h-full">
            <div className="w-full">
              <Stack
                direction="row"
                spacing={1}
                // sx={{ width: "100%", display: "block" }}
                justifyContent={"space-between"}
              >
                <h3
                  className="text-lg font-bold text-left"
                  style={{ float: "left", cursor: "pointer" }}
                  onClick={() => {
                    props.setHisOpen(true);
                    props.setHisProvider(provider);
                  }}
                >
                  {provider.orgName} -{" "}
                  {provider.firstName + " " + provider.lastName}
                </h3>
                {user.role === "admin" && (
                  <Stack direction={"row"} spacing={0} sx={{ width: "100px" }}>
                    <div
                      style={{ width: "50px", height: "50px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        openEditModal(provider);
                      }}
                    >
                      <EditIcon className="text-magenta " />
                    </div>
                    <div
                      style={{ width: "50px", height: "50px" }}
                      className="cursor-pointer"
                      onClick={() => {
                        openNotesEditor(provider);
                      }}
                    >
                      <DescriptionIcon className="text-magenta " />
                    </div>
                  </Stack>
                )}
              </Stack>
              <Grid container spacing={2} textAlign={"left"}>
                <Grid item xs={6}>
                  <PublicIcon fontSize="small"></PublicIcon>
                  <a target="_blank" href={cleanUrl(provider.url)}>
                    {provider.url}
                  </a>
                </Grid>
                <Grid item xs={6}>
                  <PhoneIcon fontSize="small"></PhoneIcon>
                  {provider.phone}
                </Grid>
                <Grid item xs={6}>
                  <MailOutlineIcon fontSize="small"></MailOutlineIcon>
                  <a href={`mailto:${provider.email}`}>{provider.email}</a>
                </Grid>
                {provider.calendarLink && (
                  <Grid item xs={6}>
                    <CalendarMonthIcon fontSize="small"></CalendarMonthIcon>
                    <a target="_blank" href={cleanUrl(provider.calendarLink)}>
                      Schedule Meeting
                    </a>
                  </Grid>
                )}

                <Grid item xs={6}>
                  <LocationOnIcon fontSize="small"></LocationOnIcon>
                  {provider.address}
                </Grid>
              </Grid>
            </div>
            <div className="m-2">
              <h3 className="text-sm text-justify">
                {provider.description ? (
                  <ul style={{ listStyleType: "circle" }}>
                    {provider.description
                      .split(",")
                      .map((item: any, index: number) => {
                        return <li key={index}>{item}</li>;
                      })}
                  </ul>
                ) : (
                  "No description available"
                )}
              </h3>
            </div>

            {/* tags */}
            {props.tagDisplay && provider.tags ? (
              <div className="mt-auto flex justify-left flex-wrap">
                {provider.tags.map((tag: any, index: number) => (
                  <Button
                    value={tag}
                    onClick={props.handleTagClick}
                    key={index}
                    style={{
                      marginRight: "5px",
                      textTransform: "none",
                      marginBottom: "5px",
                    }}
                    variant="contained"
                    sx={{
                      color: "black",
                      backgroundColor: "#ffb30080",
                      "&:hover": {
                        backgroundColor: "#bd5187",
                        color: "white",
                      },
                    }}
                  >
                    {tag}
                  </Button>
                ))}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Provider;
